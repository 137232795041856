import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { Masquerade, MasqueradeService } from "../../services/masquerade.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Bind } from "../../decorators/bind.decorator";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { ContactService } from "../../services/contact.service";
import { jSith } from "../../util/jquery-replacement";
import { DropDownItem } from "../../pojo/DropDownItem";

@TypeManagerDecorator("s25-ng-masquerade-mapping")
@Component({
    selector: "s25-ng-masquerade-mapping",
    template: `
        <div *ngIf="isInit" class="masquerade-mapping">
            <div class="templateRow">
                <label for="assignedBy" class="ngBold">Masquerade By: </label>
                <s25-contact-dropdown
                    [r25UserOnly]="true"
                    [(chosen)]="chosenBy"
                    class="c-margin-bottom--half"
                ></s25-contact-dropdown>
            </div>

            <div class="templateRow">
                <label for="assignedBy " class="ngBold">Masquerade As:</label>
                <s25-ng-multiselect-search-criteria
                    [type]="'contacts'"
                    [selectedItems]="multiSelectBeanAssignAs.selectedItems"
                    [modelBean]="multiSelectBeanAssignAs"
                    [popoverOnBody]="true"
                    [popoverPlacement]="'bottom-left top-left top left'"
                    [customFilterValue]="'&is_r25user=1'"
                ></s25-ng-multiselect-search-criteria>
            </div>

            <div class="templateRow">
                <label for="assignedBy" class="ngBold">Expiration Date: </label>
                <s25-ng-editable-date
                    [(val)]="expirationDt"
                    (valChange)="onChange($event)"
                    [alwaysEditing]="true"
                ></s25-ng-editable-date>
            </div>

            <div class="templateRow extraLength">
                <div class="buttons c-margin-top--half">
                    <button class="aw-button aw-button--primary c-margin-right--single" (click)="onSave()">Save</button>
                    <button class="aw-button aw-button--outline" (click)="onCancel()">Cancel</button>
                </div>
            </div>
        </div>
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .masquerade-mapping {
            max-width: 800px;
            min-height: 600px;
        }

        .templateRow {
            display: flex;
            gap: 1em;
            align-items: top;
            margin: 0.5em 0;
        }

        .templateRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .multiSelected {
            padding-left: 1em;
        }

        .templateRow > input {
            min-width: 15em;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }
    `,
})
export class S25MasqueradeMappingComponent implements OnInit {
    @Input() item?: Masquerade;
    @Input() mapping?: boolean = true;
    isInit: boolean = false;
    masquerade: Masquerade = {
        id: 0,
        sourceId: null,
        targetId: null,
        expirationDt: null,
    };
    @Output() saved = new EventEmitter<{ id: number }>();
    @Output() cancelled = new EventEmitter<void>();
    @Output() modelValueChange = new EventEmitter();

    masqueradeBy: any;
    masqueradeAs: any;
    expirationDt: any;
    chosenAs: any;
    chosenBy: DropDownItem;
    errorMsg = "";
    multiSelectBeanAssignAs: DropDownItem = {
        singleSelect: false,
        showResult: true,
    };
    filterUserIds: any = [-1]; // user object25 ID = -1
    service25Id: number; // servicer25 vary not always is 1
    masqueradeList: any = [];
    matchFound: any = [];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        // get masqueredades list when add/edit make sure there is no depulicated
        let masquerades = await MasqueradeService.getMasqueradeUsers();
        masquerades ? (this.masqueradeList = S25Util.array.forceArray(masquerades)) : (this.masqueradeList = []);

        this.getService25Id();
        this.masquerade = S25Util.deepCopy(this.item);

        // setTimeout(() => {
        let getTargetName = this.item.targetLastName
            ? this.item.targetLastName + ", " + this.item.targetFirstName
            : this.item.targetFirstName;
        let getSourceName = this.item.sourceLastName
            ? this.item.sourceLastName + ", " + this.item.sourceFirstName
            : this.item.sourceFirstName;
        this.item.expirationDt != "1900-01-01T00:00:00" ? (this.expirationDt = this.item.expirationDt) : "";
        this.chosenBy = { itemId: this.item.sourceId, itemName: getSourceName };
        this.chosenAs = { itemId: this.item.targetId };
        if (this.item.id > 0) {
            this.multiSelectBeanAssignAs = {
                singleSelect: true,
                showResult: true,
                selectedItems: [{ itemId: this.item.targetId, itemName: getTargetName }],
            };
        }
        this.isInit = true;
        this.cd.detectChanges();
        // }, 60);
    }

    editAssignedAs = () => {
        console.log({ multiSelectBeanAssignAs: this.multiSelectBeanAssignAs });
    };

    onChange(e: any) {
        //console.log({e:e});
    }

    validate() {
        this.errorMsg = "";
        return true;
    }

    contactsLoaded = () => S25LoadingApi.destroy(this.elementRef.nativeElement);

    @Bind
    async onSave() {
        if (!this.validate()) return;
        this.errorMsg = "";

        let ok: any;

        if (this.expirationDt !== null && this.expirationDt !== "") {
            this.expirationDt = S25Datefilter.transform(this.expirationDt, "yyyy-MM-dd") + " 00:00:00";
        } else {
            this.expirationDt = "1900-01-01 00:00:00";
        }

        let finalSelectedItems: any = [];
        jSith.forEach(this.multiSelectBeanAssignAs.selectedItems, (s: any, item: any) => {
            let findNew: any = this.masqueradeList.find((a: any) => {
                return item.itemId === a.targetId && this.chosenBy.itemId === a.sourceId;
            });
            !findNew ? finalSelectedItems.push(item) : this.matchFound.push(item);
        });

        let addedItems: any;
        if (this.masquerade.id === 0) {
            addedItems = [];
            for (let i = 0; i < finalSelectedItems.length; i++) {
                let itemId = finalSelectedItems[i].itemId;
                if (itemId !== this.service25Id && itemId !== -1) {
                    addedItems.push({
                        masqueradeId: this.masquerade.id,
                        sourceId: this.chosenBy.itemId,
                        targetId: itemId,
                        expirationDt: this.expirationDt,
                    });
                } else {
                    itemId === -1
                        ? alert("Object25 user could not be masqueraded.")
                        : alert("Service25 user could not be masqueraded.");
                }
            }
        } else {
            if (this.chosenAs.itemId !== this.service25Id && this.chosenAs.itemId !== -1) {
                let find: any = this.masqueradeList.find((a: any) => {
                    if (this.masquerade.id !== a.id) {
                        return (
                            this.multiSelectBeanAssignAs.selectedItems[0].itemId === a.targetId &&
                            this.chosenBy.itemId === a.sourceId
                        );
                    } else {
                        return false;
                    }
                });

                if (!find) {
                    addedItems = [
                        {
                            masqueradeId: this.masquerade.id,
                            sourceId: this.chosenBy.itemId,
                            targetId: this.multiSelectBeanAssignAs.selectedItems[0].itemId,
                            expirationDt: this.expirationDt,
                        },
                    ];
                } else {
                    alert("Could not be masqueraded, already exists.");
                    return;
                }
            } else {
                this.chosenAs.itemId === -1
                    ? alert("Object25 user could not be masqueraded.")
                    : alert("Service25 user could not be masqueraded.");
                return;
            }
        }

        let root: any = {};
        if (addedItems && addedItems.length > 0) root.addedItems = addedItems;

        ok = await MasqueradeService.putMasqueradeUser(root);

        if (ok) this.saved.emit(this.matchFound);
    }

    onCancel() {
        this.cancelled.emit();
    }

    async getService25Id() {
        let service25Id = await ContactService.getContactByUsername("service25");
        this.service25Id = service25Id.contact_id;
        this.filterUserIds.push(this.service25Id);
    }
}
