import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from "@angular/core";
import { Masquerade, MasqueradeService } from "../../services/masquerade.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { ModalService } from "../modal/modal.service";
import { UserprefService } from "../../services/userpref.service";
import { ContactService } from "../../services/contact.service";
import { Api } from "../../api/api";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";

export class MasqueradeRolesApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        return Api.callApiFn(
            relativeElem,
            "s25-ng-masquerade-roles",
            null,
            null,
            (comp: S25MasqueradeRolesComponent) => {
                comp && comp.refreshF();
            },
        );
    }
}

@TypeManagerDecorator("s25-ng-masquerade-roles")
@Component({
    selector: "s25-ng-masquerade-roles",
    template: `
        <div *ngIf="isInit">
            <div *ngIf="getRoles && getRoles.length === 0">None</div>
            <div *ngIf="getRoles && getRoles.length > 0">
                <span class="c-margin-right--half  ngInlineBlock">Show Expired</span>
                <s25-ng-checkbox
                    (modelValueChange)="this.expiredChange($event)"
                    [modelValue]="this.includedExpired"
                ></s25-ng-checkbox>

                <table class="c-margin-top--half">
                    <tr>
                        <td
                            class="ngBold"
                            *ngIf="(currentUsername === 'service25' || groupId === -1) && modelData.canEdit"
                        >
                            Delete
                        </td>
                        <td class="ngBold">Contact Name</td>
                        <td class="ngBold">Expiration Date</td>
                    </tr>

                    <tr *ngFor="let r of getRoles; let i = index">
                        <td *ngIf="(currentUsername === 'service25' || groupId === -1) && modelData.canEdit">
                            <svg (click)="remove(r.id)" class="c-svgIcon ngCpointer" role="img">
                                <title>Delete</title>
                                <use
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                ></use>
                            </svg>
                        </td>
                        <td class="noWrap">
                            <s25-item-contact
                                [modelBean]="{ itemId: r.targetId, itemName: r.itemName }"
                                [includeTypeIcon]="true"
                            ></s25-item-contact>
                        </td>
                        <td>
                            <span *ngIf="!r.expirationDt || r.expirationDt === '1900-01-01T00:00:00'">
                                <span *ngIf="!modelData.canEdit" class="ngRed"> No Expiration </span>
                                <span *ngIf="modelData.canEdit">
                                    <s25-ng-editable-date
                                        (valChange)="onChange($event, r)"
                                        [alwaysEditing]="true"
                                        [readOnly]="!modelData.canEdit"
                                        [placeholder]="'No Expiration'"
                                    ></s25-ng-editable-date>
                                </span>
                            </span>
                            <span *ngIf="r.expirationDt && r.expirationDt !== '1900-01-01T00:00:00'">
                                <s25-ng-editable-date
                                    [(val)]="r.expirationDt"
                                    (valChange)="onChange($event, r)"
                                    [alwaysEditing]="true"
                                    [readOnly]="!modelData.canEdit"
                                ></s25-ng-editable-date>
                            </span>
                        </td>
                    </tr>
                </table>
            </div>

            <div *ngIf="(currentUsername === 'service25' || groupId === -1) && modelData.canEdit">
                <button class="aw-button aw-button--primary" (click)="onCreateClick()">Add New Contact</button>
            </div>
        </div>
    `,
    styles: `
        tr td:first-child {
            width: 10%;
            white-space: nowrap;
        }

        ::ng-deep .s25-item-name {
            display: inline-block;
        }

        floatLeft {
            float: left !important;
        }

        :host ::ng-deep .s25-item-holder {
            display: flex;
            align-items: center;
        }

        :host ::ng-deep .s25-item-type-icon-holder-contact {
            position: relative;
            bottom: 1px;
        }
    `,
})
export class S25MasqueradeRolesComponent implements OnInit {
    @Input() modelData?: any;
    @Input() perm?: any;
    isInit: boolean = false;
    getRoles: Masquerade[] = [];
    groupId: number;
    currentUsername: string;
    includedExpired: boolean = true;
    getRolesOrig: any = [];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        if (this.modelData) {
            this.getRoles = await MasqueradeService.getMasqueradeUsers(this.modelData.itemId);
            this.groupId = await UserprefService.getGroupId();
            this.getRoles = S25Util.array.forceArray(this.getRoles);

            this.getRoles.map((r: Masquerade) => {
                r.itemId = r.targetId;
                r.targetFirstName ? (r.itemName = r.targetFirstName + " " + r.targetLastName) : r.targetLastName;
                return r;
            });

            this.getRolesOrig = S25Util.deepCopy(this.getRoles);

            this.currentUsername = await ContactService.getCurrentUsername();
            this.isInit = true;
            this.cd.detectChanges();
        }
    }

    async onCreateClick() {
        ModalService.modal("edit-masquerade", {
            masquerade: {
                id: 0,
                sourceId: "",
                targetId: "",
                expirationDt: null,
            },
            title: `Create Masquerade`,
            onSave: this.refresh,
        });
    }

    refreshF() {
        this.ngOnInit();
    }

    refresh() {
        let el: any = document.body;
        MasqueradeRolesApi.refresh(el);
    }

    async remove(id: number) {
        await MasqueradeService.deleteMasqueradeUser(id).catch(this.error);
        this.ngOnInit();
    }

    async onChange(e: any, r: Masquerade) {
        let ok: any;
        let root: any = {};
        if (e !== null && e !== "") {
            r.expirationDt = S25Datefilter.transform(e, "yyyy-MM-dd") + " 00:00:00";
        } else {
            r.expirationDt = "1900-01-01 00:00:00";
        }

        let addedItems: Masquerade[] = [
            {
                masqueradeId: r.id,
                sourceId: r.sourceId,
                targetId: r.targetId,
                expirationDt: r.expirationDt,
            },
        ];

        root.addedItems = addedItems;
        ok = await MasqueradeService.putMasqueradeUser(root);
        if (ok) this.ngOnInit();
    }

    expiredChange(e: any) {
        if (!e) {
            this.getRoles = this.getRoles.filter(
                (i: any) =>
                    S25Datefilter.transform(i.expirationDt, "yyyy-MM-dd") >
                        S25Datefilter.transform(new Date(), "yyyy-MM-dd") || i.expirationDt === "1900-01-01T00:00:00",
            );
        } else {
            this.getRoles = this.getRolesOrig;
        }
        this.cd.detectChanges();
    }

    error(error: any) {
        S25Util.showError(error);
    }
}
